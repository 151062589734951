import makeDataStore from '../LocalDataStore';
import PageManager from './PageManager';
import Utils from './Utils';
import Tracking from './Tracking';

export default function(app) {
    // init data storage in localstorage of the browser and access through app.api.
    makeDataStore(app);

    // Helper methods managing pages and router
    PageManager(app);

    // Various other helper methods
    Utils(app);

    // Tracking manager
    Tracking(app);
}