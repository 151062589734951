import { createStore } from 'vuex'

//  Create a new store instance.
const store = createStore({
    state() {
        return {
            ready: false,
            selectedYear:null
        }
    },
    mutations: {
        ready(state) {
            state.ready = true;
        },
        setSelectedYear(state, value) {
            state.selectedYear = value;
        },
    },
    getters: {
        ready(state) {
            return state.ready
        },
        getSelectedYear(state) {
            return state.selectedYear;
        },
    },
    actions: {
        ready(context) {
            context.commit('ready')
        },
    }
})

export default store;
