import { createRouter, createWebHashHistory } from 'vue-router'
import Page from '../components/core/page/Page.vue'

const routes = [
  { path: "/:id*", component: Page}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// Send events to Tracking API
router.afterEach((to, from) => {
  app.api.Tracking.navigation("navigation", to.fullPath, "");
})

export default router
