const requireComponent = require.context('../components/component-library', true, /\.vue$/);
const componentsRepository = {};

// loop through all the .vue files in the directory and subdirectories
requireComponent.keys().forEach(fileName => {
    // get the default export for each file
    const componentConfig = requireComponent(fileName);
    // extract the component name from the file name
    // Component name MUST be the same as the filename
    const componentName = fileName.split('/').pop().replace(/\.\w+$/, '');

     // add the component to the repository object, with its name as the key
    componentsRepository[componentName] = componentConfig.default || componentConfig;
});


const repo = {
    //Register components globally from the generated components above
    setup(app) {
        Object.keys(componentsRepository).forEach(name => {
            app.component(name, componentsRepository[name])
        })
    },

    // Check if a component has been registered 
    has(componentName) {
        return componentName in componentsRepository
    }
}


export default repo