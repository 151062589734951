<template>
    <nav class="HotspotContainer">
        <component :is='getHotspotComponent(hotspot)' 
            v-for='(hotspot) in renderedHotspots'
            :key='hotspot.id' :hotspot='hotspot'
            @hotspotPressed="hotspotPressed">
        </component>
    </nav>
</template>

<script>
import defaultHotspot from './defaultHotspot.vue';
import hiddenHotspot from './hiddenHotspot.vue';

export default {
    props: ['hotspots'],
    methods: {
        getHotspotComponent(hotspot) {
            let componentName = hotspot.type;
            switch(componentName) {
                case 'hiddenHotspot':
                    return hiddenHotspot;

                default:
                    return defaultHotspot;
            }

            // TODO noget automatisk hotspot registrering så man slipper for at import manuelt og referere til dem.
            /* return import(`./${componentName}.vue`)
            .then(({ default: component }) => component) 
            */
        },

        getRoute(hotspot) {
            let hotspotReferenceID = Object.values(hotspot.data.type)[0];

            if(Object.keys(hotspot.data.type)[0] == 'scene') {
                return app.api.PageManager.getRouteFromID(hotspotReferenceID);
            } else if (Object.keys(hotspot.data.type)[0] == 'content') {
                return app.api.PageManager.getCurrentRoute().split(":").slice(0)[0] + `:${app.api.PageManager.getComponentSlugFromHotspotID(hotspot.id)}`
            } else {
                return '/'
            }
        },
        hotspotPressed(e) {
            this.$emit('hotspotPressed', e)
        }
    },
    computed: {
        renderedHotspots() {
            let hotspots = this.hotspots.filter(hotspot => hotspot.data.hidden === false).map((renderedhotspot, i) => {
                renderedhotspot.index = i;
                renderedhotspot.route = this.getRoute(renderedhotspot);
                renderedhotspot.componentData = Object.keys(renderedhotspot.data.type)[0] === 'content' ? app.api.PageManager.getComponentDataFromHotspotID(renderedhotspot.id) : null
                return renderedhotspot;
            });

            return hotspots;
        }
    }
}
</script>

<style lang="scss">
.HotspotContainer {
    position: relative;
    width: 100%;
    height: 100%;
    pointer-events: none !important;
    z-index: 2;
    &>* {
        pointer-events: all;
    }
}

.Hotspot {
    position: absolute;
    cursor: pointer;

    &--hidden {
        display: none;
    }
}
    
</style>