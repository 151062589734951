<template>
    <router-link :to="homeURL" id="logo">
        <img src="@/assets/img/ui/logo-ecdp.png">
    </router-link>
</template>
<script>
export default {
    computed: {
        homeURL() {
            return app.api.PageManager.getRootPage().route
        }
    }
}
</script>
<style lang="scss" scoped>
    #logo {
        position: absolute;
        width: 237px;
        top: 68px;
        right: 70px;
        z-index: 2;
        pointer-events: all;
      
        cursor: pointer;
        
        &>img {
            width: 100%;
        }
    }
</style>