import config from "@/config";

export default function Utils(app) {
  app.api.Utils = Object.freeze({
    getMediaPath,
    getMediaType,

    fullScreenEnable,
    fullScreenDisable,
    isFullscreen,
    toggleFullscreen,

    getBrowser,
    preloadVideo,
    preloadImage,

    hexToRGB,
  });

  // Resolve path to media asset
  function getMediaPath(url = '') {
    const mediaFolder = config.mediaFolder;

    if (/http/.test(mediaFolder)) {
      const [protokol, host] = app.config.mediaFolder.split('//')
      return `${protokol}//` + `${host}/${url.replace(/(.*)\/media\//g, '')}`.replace(/\/{1,}/g, '/')
    } else {
      return `${mediaFolder}/${url.replace(/(.*)\/media\//g, '')}`.replace(/\/{1,}/g, '/')
    }
  }

  function getMediaType(src) {
    // regular expression to match image and video file extensions
    var imageExtensions = /\.(jpe?g|png|gif|bmp|webp|svg)$/i;
    var videoExtensions = /\.(mp4|m4v|ogg|webm|mkv|flv|avi|wmv|mov)$/i;

    // determine the file type based on the src string
    let fileType = "unknown";
    if (src.match(imageExtensions)) {
      fileType = "image";
    } else if (src.match(videoExtensions)) {
      fileType = "video";
    }

    return fileType;
  }

  /* FULLSCREEN */
  function fullScreenEnable() {
    var element = document.body;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  }

  function fullScreenDisable() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }

  function isFullscreen() {
    return window.innerHeight == screen.height;
  }

  function toggleFullscreen() {
    if (isFullscreen()) {
      fullScreenDisable();
    } else {
      fullScreenEnable();
    }
  }

  function getBrowser() {
    // Get the user agent string
    const userAgent = navigator.userAgent;

    // Check for Microsoft Edge
    if (userAgent.indexOf("Edge") > -1) {
      return "Microsoft Edge";
    }

    // Check for Opera
    if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
      return "Opera";
    }

    // Check for Chrome
    if (userAgent.indexOf("Chrome") > -1) {
      return "Google Chrome";
    }

    // Check for Safari
    if (userAgent.indexOf("Safari") > -1) {
      return "Safari";
    }

    // Check for Firefox
    if (userAgent.indexOf("Firefox") > -1) {
      return "Mozilla Firefox";
    }

    // Default to other
    return "Unknown";
  }

  async function preloadVideo(src) {
    let realSrc = getMediaPath(src);

    // Fetch the video using the specified src
    const response = await fetch(realSrc);

    // Check if the fetch was successful
    if (!response.ok) {
      throw new Error(`Error preloading video: ${response.statusText}`);
    }

    // Check the content type of the response
    const contentType = response.headers.get("content-type");
    if (!contentType.includes("video/mp4") && getBrowser() == "Safari") {
      console.log(
        "Invalid video content type or format for current browser Safari."
      );
      return Promise.resolve();
    }

    // Get the video data from the response
    const videoBlob = await response.blob();

    // Create an object URL from the video data
    const videoUrl = URL.createObjectURL(videoBlob);

    // Create a new video element
    const video = document.createElement("video");
    video.preload = "auto";
    video.setAttribute("playsinline", "");

    // Set the src of the video to the object URL
    video.src = videoUrl;

    // Return a promise that resolves once the video has finished preloading
    return new Promise((resolve, reject) => {
      video.addEventListener("canplay", () => {
        resolve();
      });
      video.addEventListener("error", () => {
        reject(new Error("Video preload failed."));
      });
      video.load();
    });
  }

  async function preloadImage(src) {
    // create Image element and append it to the dom for preload
    const image = new Image();
    image.src = app.api.Utils.getMediaPath(src);
    image.onerror = function (error) {
      console.error(`Error preloading image: ${error}`);
    };
  }

  // math and style heplers

  function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha != null) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }
}
