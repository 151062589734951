
<template>
    <article class="simpleArticle" ref="main">

        <div class="simpleArticle__background">
            <!-- if video -->
            <VideoPlayer v-if="isVideo(properties.backgroundMedia.src)" :src="getSrc(properties.backgroundMedia.src)"
                :controls="false" :coverFixedSize="true" class="videoplayer" :autoplay="true" :loop="true"></VideoPlayer>
            <!-- if image -->
            <img v-else-if="properties.backgroundMedia" :src="getSrc(properties.backgroundMedia.src)">
        </div>

        <div class="simpleArticle__textbox" ref="textbox" :class="{ 'snap-to-bottom': properties.textPosition == 'Bottom' }"
            :style="getTextfieldColors">

            <h3 class="title">{{ properties.title }}</h3>
            <div class="scrollarea">
                <div class="text bodytext" v-html="properties.text"></div>
            </div>

        </div>

        <a @click="closeComponent()" class="simpleArticle__close" ref="btnclose"><img src="@/assets/img/ui/arrow-back.svg" /></a>

    </article>
</template>


<script>

import { gsap } from 'gsap';
import VideoPlayer from '../_composable/VideoPlayer.vue';

export default {
    components: { VideoPlayer },
    props: [
        "properties",
        "pageData",
        "slug"
    ],
    data() {
        return {
            anim: null,
        }
    },
    mounted() {
        console.log("simple mount")
        // go!
        this.animIn();

    },
    methods: {

        animIn() {

            let timeline = gsap.timeline({});

            // main in
            timeline.to(this.$refs.main, {
                opacity: 1,
                duration: .3,
                ease: 'linear',
            })
                // text box in
                .to(this.$refs.textbox, {
                    x: 0,
                    duration: .3,
                    ease: 'power2.out',
                })
                // close button in
                .to(this.$refs.btnclose, {
                    x: 0,
                    duration: .3,
                    ease: 'power2.out',
                }, "<0.2");

            this.anim = timeline;

        },

        closeComponent() {

            if (this.anim) {
                // animate out
                this.anim.timeScale(2)
                this.anim.reverse().eventCallback("onReverseComplete", () => {
                    this.$emit("closeComponent", this.slug);
                });
            } else {
                // no animation
                this.$emit("closeComponent", this.slug);
            }
        },

        getSrc(src) {
            return app.api.Utils.getMediaPath(src);
        },

        isVideo(src) {
            return app.api.Utils.getMediaType(src) == 'video';
        }
    },
    computed: {

        getTextfieldColors() {
            const bgColor = app.api.Utils.hexToRGB(this.properties.textfieldColor, 0.5);
            return {
                borderColor: this.properties.textfieldColor,
                backgroundColor: bgColor,
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.simpleArticle {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: var(--main-color);
    padding: 180px 60px 160px 60px;
    opacity: 0;


    // background

    &__background {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;

        img,
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    // text box

    &__textbox {
        position: absolute;
        top: 180px;
        padding: 50px;
        border: 2px solid;
        border-radius: 24px;
        width: 900px;
        font-size: 24px;
        transform: translateX(-1000px);

        &.snap-to-bottom {
            top: auto;
            bottom: 160px;
        }

        .title {
            font-weight: 800;
            margin-bottom: 20px;
            text-align: left;
            font-size: 28px;
        }

        .scrollarea {
            position: relative;
            max-height: 330px;
            min-height: 150px;
            text-align: left;
            overflow-y: auto;


            .text {
                padding-right: 20px;
                font-size: 24px;
                line-height: 1.4em;
            }
        }
    }



    // close button

    &__close {
        pointer-events: all;
        position: absolute;
        top: 50%;
        right: -2px;
        width: 112px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #fff;
        border-right: none;
        border-radius: 10px 0 0 10px;
        background-color: rgb(227, 98, 35);
        background: linear-gradient(90deg, rgba(227, 98, 35, 1) 0%, rgba(227, 98, 35, 0) 100%);
        cursor: pointer;
        transition: background-color 0.3 ease-out;
        transform: translateX(100%);


        &:active {
            transition: none;
            background-color: rgba(255, 255, 255, 0.5);
        }

    }

}
</style>