<template>
    <transition name="HotspotEntrance">
        <router-link class="Hotspot" v-show="ready" :to="hotspot.route" @pointerdown="onPointerDown()"
            @pointerup="onPointerUp()" @pointerout="onPointerUp()"
            :class="[getHotspotMutatorClasses, getHotspotContentClass, getYearVisibility, getActive, getHotspotSlug]"
            :style="[getHotspotStyles]" @click="hotspotPressed">
            <div class="Hotspot__button"></div>
            <span v-html="getHotspotTitle"></span>
        </router-link>
    </transition>
</template>

<script>

export default {
    props: ['hotspot'],
    data() {
        return {
            ready: false,
            pointerdown: false,
            hotspotSiblings: []
        }
    },
    mounted() {
        this.renderHotspotCascade();
        //console.log(this.hotspot)

        // find hotspots with same title to animate them in the same pointer events
        var hs = document.getElementsByClassName(this.getHotspotSlug);
        this.hotspotSiblings = Array.from(hs);
    },
    methods: {
        renderHotspotCascade() {
            let delay = 0;
            delay = (this.hotspot.index * 50) + 100;

            setTimeout(() => {
                this.ready = true;
            }, delay);
        },

        hotspotPressed() {
            this.$emit('hotspotPressed', this.hotspot);
        },

        onPointerDown() {

            if (this.hotspotSiblings.length > 1) {
                this.hotspotSiblings.forEach(element => {
                    element.classList.add("pointerdown")
                });
            } else {
                this.pointerdown = true;
            }
        },

        onPointerUp() {

            if (this.hotspotSiblings.length > 1) {
                this.hotspotSiblings.forEach(element => {
                    element.classList.remove("pointerdown")
                });
            } else {
                this.pointerdown = false;
            }
        },





    },
    computed: {
        getHotspotStyles() {
            const gradientStart = app.api.Utils.hexToRGB(this.hotspot.data.boxColor, 1);
            //const gradientEnd = app.api.Utils.hexToRGB(this.hotspot.data.boxColor, 0);
            const gradientEnd = app.api.Utils.hexToRGB('#164d71', 0.9); // hide background animation a bit 
            return {
                left: this.hotspot.data.positionData.xPosition + 'px',
                top: this.hotspot.data.positionData.yPosition + 'px',
                width: this.hotspot.data.boxSize.width + 'px',
                height: this.hotspot.data.boxSize.height + 'px',
                borderColor: this.hotspot.data.boxColor,
                backgroundImage: `linear-gradient(180deg, ${gradientStart} 0%, ${gradientEnd} 100%)`
            }
        },
        getHotspotTitle() {
            return this.hotspot.data.alternateTitle && this.hotspot.data.alternateTitle !== '' ? this.hotspot.data.alternateTitle : this.hotspot.data.title;
        },

        // Sanitize slugs for a clean url
        getHotspotSlug() {
            var s = this.hotspot.data.title
                .toLowerCase()
                .replace(/[^\w ]+/g, '')
                .replace(/ +/g, '-')

            return encodeURI(s).replace('%E2%80%93', '-')
        },

        getYearVisibility() {
            //return { "Hotspot--activeyear": parseInt(this.$store.state.selectedYear) === parseInt(this.hotspot.data.year) && this.isHome };
            return { "Hotspot--activeyear": this.hotspot.data.year.includes(this.$store.state.selectedYear + "") && this.isHome };
        },

        getHotspotMutatorClasses() {
            return {
                'Hotspot--title-top-left': this.hotspot.data.titlePosition === "Top-Left",
                'Hotspot--title-top-right': this.hotspot.data.titlePosition === "Top-Right",
                'Hotspot--title-bottom-left': this.hotspot.data.titlePosition === "Bottom-Left",
                'Hotspot--title-bottom-right': this.hotspot.data.titlePosition === "Bottom-Right",
                'Hotspot--title-hidden': this.hotspot.data.titlePosition === "Hidden",
                'Hotspot--openside-left': this.hotspot.data.openSide === "Left",
                'Hotspot--openside-right': this.hotspot.data.openSide === "Right",
                'Hotspot--hidden': this.hotspot.data.hidden,
                'Hotspot--customicon': this.hotspot.data.alternateHotspotIcon !== null
            }
        },
        getHotspotContentClass() {
            if (this.hotspot.componentData) {
                return `Hotspot--${this.hotspot.componentData.type}`
            } else {
                return
            }
        },

        getActive() {
            return {
                'pointerdown': this.pointerdown,
            }
        },
        isHome() {
            this.$route;
            return app.api.PageManager.onHomePage();
        }
    }
}
</script>


<style lang="scss">
$hotspot-height: 28px;

.Hotspot {
    display: flex;
    border: 2px solid;
    border-radius: 24px;
    padding: 20px;
    opacity: 0;
    transition: all .3s var(--tk-easeinout);
    transform-origin: 100% 0%;
    transform: scaleX(0.05);
    pointer-events: none!important;

    &>* {
        opacity: 0;
        transition: none;

    }

    .Hotspot__button {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        height: $hotspot-height;
        width: $hotspot-height;
        background-color: transparent;
        background-image: url(@/assets/img/ui/plus.svg);
        background-size: 17px;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 7px;
        border: 2px solid #fff;

    }

    span {

        font-weight: bold;
        color: #fff;
        font-size: 20px;
        max-width: 200px;
        margin: 0 20px;
        z-index: 2;
        border-radius: 0 10px 10px 0;
        text-transform: uppercase;
        text-align: left;
    }

    &--activeyear {
        pointer-events: all!important;
        opacity: 1;
        transform: scaleX(1);
        transition: all .3s .5s ease-in-out;

        &>* {
            transition: opacity .5s .8s;
            opacity: 1;

        }
    }


    &--title-top-left {
        flex-direction: row;

        span {
            text-align: left;
        }
    }

    &--title-top-right {
        flex-direction: row-reverse;

        span {
            text-align: right;
        }
    }

    &--title-bottom-left {
        align-items: flex-end;

        span {
            text-align: left;
        }
    }

    &--title-bottom-right {
        flex-direction: row-reverse;
        align-items: flex-end;

        span {
            text-align: right;
        }
    }

    &--title-top-right,
    &--title-bottom-right,
    &--openside-left {
        transform-origin: 0% 100%;
    }

    // hidden title

    &--title-hidden {

        &>span,
        &>div {
            display: none !important;
        }
    }



    // open side

    &--openside-left {
        border-left: none;
        border-radius: 0 24px 24px 0;

        &::before {
            border-left: none !important;
            border-radius: 0 24px 24px 0 !important;
        }
    }

    &--openside-right {
        border-left: none;
        border-radius: 24px 0 0 24px;

        &::before {
            border-left: none !important;
            border-radius: 0 24px 24px 0 !important;
        }
    }


    &::before {
        position: absolute;
        border-radius: 24px;
        content: '';
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        background-color: rgba(135, 195, 210, 0.1);
        box-shadow: 1px 1px 20px 1px rgba(55, 138, 171, 1);
        z-index: -1;
        transition: opacity 0.3s linear;
        border: #fff 2px solid;
        opacity: 0;
    }

    &.pointerdown {

        transition: none;

        &::before {
            transition: none;
            opacity: 1;
        }
    }
}


// year visibility



/* entrance animation */
.HotspotEntrance-enter-active {
    transition: all 1s var(--tk-easeinout);
}

.HotspotEntrance-leave-active {
    transition: all 0.3s var(--tk-easeinout);
}

.HotspotEntrance-enter-from {
    transform: scaleX(0);
    opacity: 0;
}

.HotspotEntrance-leave-to {
    opacity: 0;
}
</style>