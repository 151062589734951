<template>
  <nav id="UiLayer">
    
    <Logo></Logo>

    <Menu></Menu>

    <RouteMap></RouteMap>


  </nav>
</template>
<script>
import Logo from "../ui/Logo.vue";
import Menu from "../ui/Menu.vue";
import RouteMap from "../ui/RouteMap.vue";


export default {
  components: {
    // Ui Components
    Logo,
    Menu,
    RouteMap

  },
  data() {
    return {
    };
  },
  methods: {

  },
  computed: {
    onHomePage() {
      this.$route;
      return app.api.PageManager.onHomePage();
    }
  }
};
</script>

<style lang="scss">
#UiLayer {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  // * {
  //   pointer-events: all;
  // }
}


</style>