<template >
  <AspectContainer>
    <EasyReach>
      <UiLayer></UiLayer>
      <TransitionVideos v-if="hasTransitionVideoes"></TransitionVideos>
      <router-view></router-view>
    </EasyReach>
  </AspectContainer>
</template>


<script>
import config from '@/config';
import AspectContainer from '../core/AspectContainer.vue';
import UiLayer from "../core/UiLayer.vue";
import TransitionVideos from "../core/TransitionVideos.vue";
import EasyReach from "../core/EasyReach.vue";

export default {

  components: {
    AspectContainer,
    EasyReach,
    UiLayer,
    TransitionVideos
  },
  data() {
    return {
      timer: null,
    }
  },
  beforeMount() {
    document.title = this.pageTitle;
  },
  computed: {
    pageTitle() {
      this.$route;
      var breadcrumbs = app.api.PageManager.getBreadcrumbs().filter(crumb => crumb !== undefined)
      return (breadcrumbs.length > 1) ? `${config.name} - ${breadcrumbs[breadcrumbs.length - 1].title}` : config.name;
    },
    hasTransitionVideoes() {
      if (!app.api.PageManager.getCustomComponentFromType("videoTransitions")) {
        return false
      } else {
        return true
      }
    }
  },
  watch: {
    pageTitle() {
      document.title = this.pageTitle;
    }
  }
}
</script>

