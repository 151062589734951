<template>
  <article class="VideoPlayer" v-if="ready"
    :class="{ 'VideoPlayer--videopaused': isVideoPaused, 'VideoPlayer--cover-fixed-size': coverFixedSize }">
    <video ref="video" v-if="src" :autoplay="autoplay" :controls="controls" :controlslist="controlsList" :mute="mute"
      :loop="loop" :poster="posterUrl" @playing="isVideoPaused = false" @pause="isVideoPaused = true" type="video/mp4"
      playsinline webkit-playsinline crossorigin @click="toggle()">
      <source :src="videoUrl">
    </video>
  </article>
</template>
<script>


export default {
  props: {
    src: {
      required: true,
    },
    posterSrc: String,
    autoplay: Boolean,
    controls: {
      type: Boolean,
      default: true
    },
    controlsList: {
      type: String,
      default: "noplaybackrate nodownload"
    },
    loop: Boolean,
    mute: Boolean,
    coverFixedSize: Boolean,
 

  },
  data() {
    return {
      ready: false,
      isVideoPaused: !this.autoplay
    }
  },
  mounted() {
    this.ready = true;
   },
  methods: {
    toggle() {
      if (this.isVideoPaused) {
        this.$refs.video.play();
      } else {
        this.$refs.video.pause();
        this.$emit("stopped")
      }
    },
    reset(){
      this.$refs.video.currentTime = 0;
      this.$refs.video.pause();
    }
  },
  computed: {
    videoUrl() {
      return app.api.Utils.getMediaPath(this.src)
    },
    posterUrl() {
      if (this.posterSrc)
        return app.api.Utils.getMediaPath(this.posterSrc)
    }
  }
};
</script>

<style lang="scss">
.VideoPlayer {
  // width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;


  &>video {
    width: 100%;
  }

  &--cover-fixed-size {
    width: 100%;
    height: 100%;

    &>video {
      height: 100%;
      object-fit: cover;
    }
  }



  &::after {
    content: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 60px;
    height: 60px;
    pointer-events: none;
    background-image: url(@/assets/img/ui/play.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    z-index: 3;
  }

  &::before {
    content: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-52%) translateY(-50%);
    width: 120px;
    height: 120px;
    pointer-events: none;
    border-radius: 50%;
    // background: rgba(23,79,114,0.3); 
    opacity: 1;
    z-index: 3;
    border:6px solid #fff;
  }

  &--videopaused {
    video {
      cursor: pointer;
    }

    &::after,
    &::before {
      content: '';
    }
  }
}


.fullscreenVideo__close {
  position: absolute;
  left: 100%;
  top: 0;
  height: 60px;
  width: 60px;
  background: var(--main-color);
  background-image: url(@/assets/img/ui/close.svg);
  background-position: center;
  background-repeat: no-repeat;
}
</style>