
<template>
    <article class="richArticle" ref="main">

        <!-- media -->
        <MediaSlideShow class="mediaslides" :mediaList="properties.media"></MediaSlideShow>

        <!-- text -->
        <div class="richArticle__textbox" :style="getTextfieldGradient" ref="textbox">
            <h3 class="title">{{ properties.title }}</h3>
            <div class="scrollarea">
                <div class="text bodytext" v-html="properties.text"></div>
                <div class="textfader"></div>
            </div>
        </div>

        <!-- linkbox -->
        <div class="richArticle__linkbox" ref="linkbox">
            <div class="header">MAIN MENU</div>
            <nav>
                <a @click="goHome()"><img src="@/assets/img/ui/home.svg" />Back to Route</a>
                <a v-for="link in properties.links" @click="openLink(link.contentID)"><span></span>{{ link.title }}</a>
            </nav>
        </div>

        <span class="richArticle__statistics" ref="statistics" v-if="properties.statisticMedia">
            <MediaSlideShow class="statistics" :mediaList="[properties.statisticMedia]" :autoplayVideos="true"
                :loopVideos="true"></MediaSlideShow>
        </span>

    </article>
</template>


<script>

import { gsap } from 'gsap';
import MediaSlideShow from '../_composable/MediaSlideShow.vue'

export default {
    components: { MediaSlideShow },
    props: [
        "properties",
        "pageData",
        "slug"
    ],
    data() {
        return {
            anim: null
        }
    },
    mounted() {
        // go!
        this.animIn();
    },

    methods: {

        animIn() {

            let timeline = gsap.timeline({});

            // main in
            timeline.to(this.$refs.main, {
                opacity: 1,
                duration: .3,
                ease: 'linear',
            })
                // textbox in
                .to(this.$refs.textbox, {
                    opacity: 1,
                    y: 0,
                    duration: .3,
                    ease: 'power2.out',
                })
                // linkbox in
                .to(this.$refs.linkbox, {
                    opacity: 1,
                    y: 0,
                    duration: .3,
                    ease: 'power2.out',
                }, "<0.1")
                // stats in
                .to(this.$refs.statistics, {
                    opacity: 1,
                    duration: .3,
                    ease: 'linear'
                });


            this.anim = timeline;

        },

        animOut(onComplete) {

            if (this.anim) {
                // animate out
                this.anim.timeScale(2)
                this.anim.reverse().eventCallback("onReverseComplete", () => {
                    onComplete();
                });
            } else {
                // no animation
                onComplete();
            }
        },

        goHome() {

            app.api.PageManager.closeAllContentComponents();

        },

        goBack() {

            var _this = this

            this.animOut(function () {
                _this.$emit("closeComponent", _this.slug);
            });

        },

        openLink(contentID) {
            app.api.PageManager.openExplicitContentFromID(contentID);
        },
    },


    computed: {
        getTextfieldGradient() {
            const gradientStart = app.api.Utils.hexToRGB(this.properties.textfieldColor, 1);
            const gradientEnd = app.api.Utils.hexToRGB(this.properties.textfieldColor, 0);
            return {

                //borderColor: this.hotspot.data.boxColor,
                backgroundImage: `linear-gradient(180deg, ${gradientStart} 0%, ${gradientEnd} 80%)`
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.richArticle {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: var(--main-color);
    display: grid;
    grid-template-rows: 540px 630px auto;
    grid-template-columns: auto 284px;
    grid-gap: 20px;
    grid-template-areas: "media media" "text links" "widget widget";
    padding: 180px 60px 60px 60px;
    opacity: 0;


    // slide shows

    .MediaSlideShow.mediaslides {
        grid-area: media;
        border-radius: 24px;
    }


    &__statistics {

        opacity: 0;
        grid-area: widget;
        align-self: flex-end;

        .MediaSlideShow.statistics {

            background-color: transparent;
            height: 480px;

            // if padding included in media
            // height: 450px;
            // margin: -60px;
            // width: calc(100% + 120px);
        }
    }


    // text box

    &__textbox {
        padding: 30px 35px;
        position: relative;
        border-radius: 24px 24px 0 0;
        transform: translateY(100px);
        opacity: 0;
        display: grid;
        height: 100%;


        .title {
            font-size: 36px;
            font-weight: 800;
            margin-bottom: 30px;
            padding-bottom: 5px;
            text-align: left;
            border-bottom: 1px #fff solid;

        }

        .scrollarea {
            width: 100%;
            height: 100%;
            text-align: left;
            padding-right: 0px;
            overflow-y: auto;
            position: relative;


            .text {
                padding-right: 20px;
                font-size: 22px;
                line-height: 1.4em;

                >ul {
                    list-style: disc; /* or your preferred list style */

                }
            }

            .textfader {
                position: fixed;
                pointer-events: none;
                height: 100px;
                width: 86%;
                bottom: 10px;
                background-image: linear-gradient(180deg, rgba(22, 77, 113, 0) 0%, rgba(22, 77, 113, 1) 80%);
            }
        }

    }



    // linkbox

    &__linkbox {
        min-height: 300px;
        height: fit-content;
        background-image: linear-gradient(180deg, rgba(34, 115, 150, 1) 0%, rgba(34, 115, 150, 0) 80%);
        border: var(--secondary-color) 1px solid;
        border-radius: 10px;
        transform: translateY(150px);
        opacity: 0;

        .header {
            border-bottom: var(--secondary-color) 1px solid;
            padding: 16px 20px;
            text-align: left;
            font-size: 24px;
            ;
        }

        nav {
            padding: 15px 0px;
            text-align: left;

            a {
                display: flex;
                align-items: center;
                text-transform: uppercase;
                // margin-bottom: 2em;
                cursor: pointer;
                position: relative;
                transition: background-color 0.3s;
                padding: 20px 20px;

                :last-of-type {
                    margin-bottom: 0;
                }

                // &:active{background:rgba(255,255,255,0.5);
                // }

                img {
                    width: 40px;
                    height: 35px;
                    object-fit: contain;
                    margin-right: 18px;
                    margin-left: -4px;
                }

                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 32px;
                    width: 32px;
                    background-color: transparent;
                    background-image: url(@/assets/img/ui/plus.svg);
                    background-size: 17px;
                    background-position: center;
                    background-repeat: no-repeat;
                    border-radius: 7px;
                    border: 2px solid #fff;
                    margin-right: 20px;
                }


                &:active {
                    background-color: var(--secondary-color);
                    transition: none;
                }
            }
        }
    }


}
</style>