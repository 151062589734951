<template>
    <div class="RouteMap" :class="{ visible: isHome }">
        <img :src="stationNamesImg">
        <img :src="getMapOfYear">

        <div class="Legend" ref="legend">
            <div><span class="yellow"></span>etcs</div>
            <div><span class="lightblue"></span>relock</div>
            <div><span class="blue"></span>recontrol</div>
            <div><span class="purple"></span>resignaling</div>
            <div><span class="turk"></span>Signals away</div>
            <div><span class="orange"></span>el2ns</div>
        </div>


        <div class="UKmap" :class="{ visible: this.$store.state.selectedYear == '-'}">
            <img src="@/assets/img/ui/UK-map.svg">
        </div>

    </div>
</template>

<script>
import { gsap } from 'gsap';
export default {
    data() {
        return {
            ready: false,
            routeMaps: [],
            stationNamesImg: "",
            legend: null,
            legendAnimation: gsap.timeline()

        }
    },
    mounted() {

        this.routeMaps = this.getRouteMaps();

        window.addEventListener("resize", this.onResize);
        this.onResize();

        this.legend = this.$refs.legend;

    },
    methods: {
        // get data from custom component
        getRouteMaps() {
            return app.api.PageManager.getCustomComponentFromType("routeMaps").data.maps;
        },

        onResize() {
            // use a smaller image if not 4K otherwise texts gets crumbled
            if (window.innerHeight > 1900) {
                this.stationNamesImg = require("@/assets/img/ui/station-names-4K.png");
            } else {
                this.stationNamesImg = require("@/assets/img/ui/station-names-HD.png");
            }
        },

        animateLegend(forceOut = false) {


            if (this.legend) {
                const children = this.legend.children;

                if (this.legendAnimation) {
                    this.legendAnimation.kill();
                    this.legendAnimation = null;
                }


                // Create a new TimelineLite instance
                const timeline = gsap.timeline();

                // out
                if (this.$store.state.selectedYear == "-" || !this.isHome) {

                    const _delay = (this.isHome) ? 0 : 1;

                    this.legendAnimation = timeline.to(children, {
                        opacity: 0,
                        y: 50,
                        duration: 0.3,
                        stagger: -0.1,
                        ease: 'power2.out',
                        delay: _delay
                    });

                } else {

                    // in (only if selected year is not "-")
                    this.legendAnimation = timeline.to(children, {
                        opacity: 1,
                        y: 0,
                        duration: 0.3,
                        stagger: 0.1,
                        ease: 'power2.out',
                    });

                }

                timeline.restart();
            }

        }

    },
    computed: {
        // find the first map matching the selected year or use default
        getMapOfYear() {
            // ..and reanimate legend whenever selectedYear changes
            this.animateLegend();
            // then get the right map
            const selectedYear = parseInt(this.$store.state.selectedYear);
            const map = this.routeMaps.find(item => parseInt(item.year) === selectedYear)?.map;
            return map ? app.api.Utils.getMediaPath(map.src) : require('@/assets/img/ui/routemap-base.png');
        },
        isHome() {
            this.$route;
            return app.api.PageManager.onHomePage();
        }

    }, watch: {
        isHome() {
            this.animateLegend();
        }
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.onRezize);
    }
}
</script>

<style lang="scss">
.RouteMap {
    height: 1362px;
    position: absolute;
    top: 229px;
    left: 341px;
    opacity: 0;
    transition: opacity 0.4s;

    * {
        pointer-events: none !important;
    }

    &>img {
        pointer-events: none !important;
        position: absolute;
        height: 100%;
    }

    &.visible {
        opacity: 1;
        transition: all 0.3s 0.2s;
    }

    .Legend {

        position: fixed;
        right: 40px;
        top: 200px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-transform: uppercase;
        font-size: 16px;

        div {

            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: -10px;
            opacity: 0;

            span {
                display: block;
                width: 54px;
                height: 54px;
                border-radius: 10px;
                background-size: 100%;
                margin-right: 10px;
                border: 1px solid;

                &.yellow {
                    background-image: url('@/assets/img/ui/sqr-yellow.png');
                    border-color: #F3A020;
                }

                &.lightblue {
                    background-image: url('@/assets/img/ui/sqr-lightblue.png');
                    border-color: #32AFCE;
                }

                &.blue {
                    background-image: url('@/assets/img/ui/sqr-blue.png');
                    border-color: #1E62B9;
                }

                &.purple {
                    background-image: url('@/assets/img/ui/sqr-purple.png');
                    border-color: #8260AD
                }

                &.turk {
                    background-image: url('@/assets/img/ui/sqr-turk.png');
                    border-color: #75CDC2
                }

                &.orange {
                    background-image: url('@/assets/img/ui/sqr-orange.png');
                    border-color: #E36223
                }
            }
        }
    }

    .UKmap {
        position: fixed;
        width: 250px;
        height: 480px;
        left: 60px;
        top:0px;
        opacity: 0;
        transition:opacity 1s;

        img {
            width: 100%;
        }

        &.visible{
            opacity: 1;
        }
    }
}
</style>