import config from "@/config";

// temp variable to hold promise data
let data = false;

// fetch method to get manifest data
export default function MakeLoader({
    MANIFEST_PATH,
    FETCH_OPTIONS,
    DataDecorator = false
}) {
    return Object.freeze({
        fetchData,
        get data() {
            return data;
        }
    })

    async function fetchData() {
        var path = MANIFEST_PATH.replace(/@lang/g, getLanguage());;
        var globalFetchOptions = FETCH_OPTIONS || {};
        var localFetchOptions = {
            cache: "no-store"
        }
        var fetchOptions = Object.assign({}, localFetchOptions, globalFetchOptions);
        return new Promise((resolve, reject) => {
            fetch(path, fetchOptions).then(o => o.json())
                .then(o => {
                    data = DataDecorator ? DataDecorator(o) : o;
                    console.log("Fetched manifest successfully")
                    resolve();
                })
                .catch(e => {
                    console.log(e)
                    reject(e)
                })
        })
    }

    function getLanguage() {
        let language = app.api.store.get("lang")
        if(!language) {
            language = config.language;
        }

        return language
    }
}