<template>
    <div id="EasyReach" :class="{ active: easyReachMode }">
        <div class="activeSign">
            <h3>Easy Reach Mode</h3>
            <div class="activeSign__subtext">Tap anywhere on the screen<br />to return to full view</div>
            <!-- <img src="arrow" /> -->
        </div>
        <slot></slot>
    </div>
    <a id="EasyReachButton" @click="toggleEasyReach()" :class="{ active: easyReachMode }">
        <img src="@/assets/img/ui/wheelchair.png">
    </a>
</template>
<script>
export default {
    data() {
        return {
            easyReachMode: false,
            changingMode: false
        }
    },
    methods: {
        toggleEasyReach() {

            if (this.changingMode)
                return;

            this.easyReachMode = !this.easyReachMode;

            // any window tap returning to normal
            if (this.easyReachMode) {
                window.addEventListener('pointerup', this.toggleEasyReach)
            } else {
                window.removeEventListener('pointerup', this.toggleEasyReach)
            }

            // avoid double event when pressing wheelchair (and window) in easyReachMode
            this.changingMode = true;
            setTimeout(() => {
                this.changingMode = false;
            }, 20)
        }
    }
}
</script>


<style lang="scss">
* {
    --easy-reach-y: calc(630px);
}

#EasyReach {
    transition: transform 0.5s var(--square-easeinout);
    width: 100%;
    height: 100%;

    &.active {
        transform: translateY(var(--easy-reach-y));

        .activeSign {
            opacity: 1;

        }
    }


    .activeSign {
        opacity: 0;
        position: absolute;
        display: flex;
        top: calc(0px - var(--easy-reach-y));
        height: var(--easy-reach-y);
        width: 100%;
        background-color: var(--main-color);
        z-index: 2;

        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        flex-direction: column;
        padding: 200px;
        transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1.000);

        h3 {
            font-size: 80px;
            font-weight: 200;
            margin-bottom: 20px;
        }

        .activeSign__subtext {
            font-size: 35px;
            font-weight: 200;
            margin-bottom: 20px;
        }

    }
}

#EasyReachButton {
    position: absolute;
    display: flex;
    bottom: 130px;
    right: 0px;
    z-index: 2;
    width: 61px;
    height: 55px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border: 2px solid #fff;
    border-right: none;
    border-radius:  10px 0 0 10px ;
    background-color: rgb(227, 98, 35);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.6) 0%, rgba(23, 79, 114, 1) 100%);
    transition: transform 0.5s var(--square-easeinout);



    &::before {
        position: absolute;
        border-radius: inherit;
        content: '';
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        background-color: var(--secondary-color);
        z-index: -1;
        transition: opacity 0.3s linear;
        opacity: 0;
    }


    img {
        width: 35px;
    }


    &.active {
        transform: translateY(210px);

        &::before {
            opacity: 0.5;
        }

    }
}
</style>