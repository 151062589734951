<template>
    <nav id="Menu" :class="{ visible: isHome }">
        <div class="custom-buttons" :class="{ active: menuOpen }">
            <a v-for="link in navigationProps.links" @click="openNavigationContent(link.contentId)" class="cbutton">
                <img :src="getSrc(link.icon.src)" />
                <span v-html="link.title"></span></a>

        </div>
        <div class="start-button" :class="{ active: menuOpen }"> <a @click="toggleMenu()">
                <span class="label-start"><img src="@/assets/img/ui/click.png" />Start</span>
                <span class="label-menu"><img src="@/assets/img/ui/implode.png" />Menu</span>
            </a>
        </div>
        <div class="timeline-navigation" :class="{ active: menuOpen }">
            <div class="explore-header">Explore Project Timeline</div>
            <div class="year-label">YEAR</div>
            <div class="yearpicker">

                <div class="left-side">Explore projects </div>

                <VueScrollPicker :options="scrollPickerOptions" v-model="scrollPickerValue" />

            </div>

            <div class="arrow-up" @click="yearArrowUp" :class="{'disabled': this.scrollPickerValue == this.scrollPickerOptions[0]}"><img src="@/assets/img/ui/arrow-down.png" /></div>
            <div class="arrow-down" @click="yearArrowDown" :class="{'disabled': this.scrollPickerValue == this.scrollPickerOptions[this.scrollPickerOptions.length-1]}"><img src="@/assets/img/ui/arrow-down.png" /></div>
        </div>
    </nav>
</template>
<script>
import { VueScrollPicker } from 'vue-scroll-picker'
export default {
    data() {
        return {
            menuOpen: false,
            navigationProps: {},
            scrollPickerOptions: ["-", 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
            scrollPickerValue: null,
            timer: null,
            timerDuration: 5000
        }
    },
    components: {
        VueScrollPicker
    },

    mounted() {

        this.navigationProps = this.getNavigationProps();


        // start timeout timer + reset on any click
        this.timerDuration = parseInt(app.manifest.data.metadata.settingsSegments.find(x => x.settingName == "timeout").settingValue) * 1000;

        this.resetTimer();
        window.addEventListener("click", this.resetTimer);

    },
    computed: {
        isHome() {
            this.$route;
            return app.api.PageManager.onHomePage();
        },
    },
    methods: {
        getNavigationProps() {
            return app.api.PageManager.getCustomComponentFromType("customNavigation").data;
        },
        toggleMenu() {
            this.menuOpen = !this.menuOpen;

            if (!this.menuOpen) {
                this.scrollPickerValue = this.scrollPickerOptions[0];
            }
        },
        openNavigationContent(contentID) {
            this.menuVisible = false;
            app.api.PageManager.openExplicitContentFromID(contentID)
        },
        getSrc(src) {
            return app.api.Utils.getMediaPath(src);
        },
        resetTimer() {
            // clear timer
            if (this.timer) {
                clearTimeout(this.timer);
            }
            // set new
            this.timer = setTimeout(() => {
                this.onTimeout();
            }, this.timerDuration);

        },

        onTimeout() {
            app.api.PageManager.navigateToHomePage();
            this.scrollPickerValue = this.scrollPickerOptions[0];
            setTimeout(() => {
                this.menuOpen = false;
            }, 1000);

        },

        yearArrowUp() {
            var currentIndex = this.scrollPickerOptions.indexOf(this.scrollPickerValue);
            var newIndex = Math.max(currentIndex - 1, 0);
            this.scrollPickerValue = this.scrollPickerOptions[newIndex];
        },

        yearArrowDown() {
            var currentIndex = this.scrollPickerOptions.indexOf(this.scrollPickerValue);
            var newIndex = Math.min(currentIndex + 1, this.scrollPickerOptions.length-1);
            this.scrollPickerValue = this.scrollPickerOptions[newIndex];
            
        }
    }, watch: {
        scrollPickerValue() {
            this.$store.commit('setSelectedYear', this.scrollPickerValue);
        }
    }
}
</script>
<style src="vue-scroll-picker/lib/style.css"></style>
<style lang="scss">
#Menu {

    $outer-glow: 1px 1px 50px 1px rgba(55, 138, 171, 0.5);

    position: absolute;
    display: flex;
    bottom: 600px;
    right: 0;
    font-size: 20px;
    z-index: 2;
    width: 240px;
    display: flex;
    flex-direction: column;
    height: 900px;
    justify-content: center;
    transform: translateX(100%);
    opacity: 0;
    transition: all 0.1s 0.3s ease-in-out;




    &.visible {
        transform: translateX(0);
        opacity: 1;
        transition: all 0.1s 0s ease-in-out;
    }


    a {
        cursor: pointer;
        font-weight: normal;
        padding: 0 10px;
        pointer-events: all;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
    }


    &>.start-button {

        height: 60px;
        transition: all 0.5s;

        a {
            position: absolute;
            height: 100px;
            width: 103%;
            border-radius: 20px 0 0 20px;
            border: 2px solid #fff;
            border-right: none;
            font-weight: bold;
            font-size: 30px;
            text-transform: uppercase;
            color: #fff;
            background-color: rgb(229, 101, 30);
            background-image: linear-gradient(90deg, rgba(229, 101, 30, 1) 0%, rgba(23, 79, 114, 1) 100%);
            margin: -10px -2px -10px -2px;
            z-index: 100;
            box-shadow: 1px 1px 30px 1px rgba(229, 101, 30, 0.5);
            -webkit-box-shadow: 1px 1px 30px 1px rgba(229, 101, 30, 0.5);
            -moz-box-shadow: 1px 1px 30px 1px rgba(229, 101, 30, 0.50);
            transition: all 0.3s ease-in-out;

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                width: 100%;
                height: 100%;
            }

            .label-start {
                opacity: 1;

                img {
                    margin-right: 20px;
                    height: 60px;
                }
            }

            .label-menu {
                opacity: 0;

                img {
                    margin-right: 20px;
                    height: 30px;
                }
            }



            &:active {

                &::before {
                    opacity: 1;
                    transition: none;
                }
            }

        }

        a::before {
            position: absolute;
            border-radius: inherit;
            content: '';
            top: -1px;
            right: -1px;
            bottom: -1px;
            left: -1px;
            background-color: rgba(255, 255, 255, 0.9);
            z-index: -1;
            transition: opacity 0.3s linear;
            opacity: 0;
        }

        &.active {
            height: 30px;

            a {
                position: absolute;
                height: 60px;

                .label-start {
                    opacity: 0;
                }

                .label-menu {
                    opacity: 1;
                }
            }
        }
    }


    // knapper over start

    &>.custom-buttons {
        // outer glow
        box-shadow: $outer-glow;
        -webkit-box-shadow: $outer-glow;
        -moz-box-shadow: $outer-glow;
        border-radius: 20px 0 0 0;
        background-image: url("@/assets/img/ui/bluebg.png");
        background-size: auto auto;
        background-position: top left;
        border-left: 2px solid var(--secondary-color);
        border-top: 2px solid var(--secondary-color);

        // animation
        transition: all 0.3s var(--tk-easeinout);
        opacity: 0;
        transform: translateY(100px);
        transform-origin: 0% 100%;

        &>* {
            pointer-events: none !important;
        }



        &.active {
            opacity: 1;
            transform: initial;

            a {
                pointer-events: all !important;
            }


        }


        a {
            border-top: 2px solid var(--secondary-color);
            height: 80px;
            justify-content: flex-start;
            align-items: center;
            transition: background-color 0.5s;


            &:active {
                background-color: var(--secondary-color);
                transition: none;
            }

            &:first-of-type {
                border-radius: 20px 0 0 0;
                border-top: none;
            }

            &:last-of-type {
                // left border go down beside the startbutton
                height: 90px;
                padding-bottom: 10px;
            }

            img {
                width: 35px;
                height: 35px;
                object-fit: contain;
                margin: 0 20px 0 20px;
            }

            span {
                text-align: left;
            }
        }
    }


    // knapper under start

    &>.timeline-navigation {
        // outer glow
        position: relative;
        box-shadow: $outer-glow;
        -webkit-box-shadow: $outer-glow;
        -moz-box-shadow: $outer-glow;
        border-radius: 0 0 0 20px;
        height: 360px;
        border-left: 2px solid var(--secondary-color);
        border-bottom: 2px solid var(--secondary-color);
        border-radius: 0 0 0 20px;
        transition: all 0.3s var(--tk-easeinout);
        opacity: 0;
        transform: translateY(-100px);
        background-image: url("@/assets/img/ui/bluebg.png");
        background-size: auto auto;
        background-position: bottom left;
        // transform-origin: 0% 0%;
        pointer-events: none;

        &.active {
            opacity: 1;
            transform: initial;
            pointer-events: all !important;

            &>*{
                pointer-events: all !important;
            }
        }

        &>.explore-header {
            pointer-events: none;

            min-height: 110px;
            border-bottom: 2px solid var(--secondary-color);
            display: flex;
            justify-content: flex-start;
            text-align: left;
            align-items: center;
            padding: 0 30px;

            text-transform: uppercase;
            font-size: 16px;

            // left border go up beside the startbutton
            padding-top: 20px;

        }

        &>.year-label {
            position: absolute;
            background: rgba(135, 195, 210, 0.4);
            width: 100%;
            height: 36px;

            text-align: left;
            padding-left: 38px;
            display: flex;
            align-items: center;
            pointer-events: none;
            top: 206px;
            font-size: 20px;
            z-index: 0;
        }

        &>.yearpicker {
            display: flex;
            align-items: center;
            font-size: 32px;
            height: 190px;
            overflow: hidden;
            margin-top: 2px;
            color: #fff;
            position: absolute;
            width: 100%;

            .left-side {
                font-size: 20px;
                color: #b4c6d1;
                border-right: 2px solid var(--secondary-color);
                height: 150px;
                margin-top: 30px;
            }

            .vue-scroll-picker {
                width: 60%;
                height: 5em;
                margin-top: 35px;
            }

            .vue-scroll-picker-item {
                font-weight: bold;
                color: rgba(255, 255, 255, 0.5);
                line-height: 1.1em;

            }

            .vue-scroll-picker-item-selected {
                color: #fff;
            }


            .vue-scroll-picker-layer-top {
                background: linear-gradient(180deg, rgba(23, 79, 114, 1) 20%, rgba(23, 79, 114, 0.2));
                border: none;
                height: calc(50% - 0.6em);
            }

            .vue-scroll-picker-layer-bottom {
                background: linear-gradient(0deg, rgba(23, 79, 114, 1) 20%, rgba(23, 79, 114, 0.2));
                border: none;
                height: calc(50% - 0.6em);
            }

            .vue-scroll-picker-layer-selection {
                top: calc(50% - 0.6em);
                bottom: calc(50% - 0.6em);
                // background: rgba(255, 255, 255, 0.5);
            }
        }

        .arrow-up,
        .arrow-down {
            position: absolute;
            right: 10px;
            bottom: 10px;
            width: 100px;
           padding: 5px 0;
            // background-color: rgba(255,255,255,0.4);
            // background-color: rgba(55, 138, 171, 0.5);
            transition:opacity 0.6s;

            &:active {
                  background-color: rgba(55, 138, 171, 0.5);
            }

            img {
                width:30px;
                height: auto;
            }

            &.disabled{
                opacity: .4;
                pointer-events: none;
            }
        }

        .arrow-up {
            bottom: 200px;
            transform: rotate(180deg);
        }


    }



}
</style>