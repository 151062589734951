<template>
  <article class="backgroundMedia" :class="{'backgroundMedia--link': hasSceneLink || hasContentLink}" :style="[getPosition, getSize]" @click="navigateTo">
    <img v-if="mediaType === 'image'" :src="getSrc">

    <video v-else-if="mediaType === 'video'" autoplay muted loop>
      <source :src="getSrc">
    </video>
  </article>
</template>

<script>
export default {
  props: [
    "properties",
    "pageData"
  ],
  methods: {
    navigateTo() {
      if(this.hasSceneLink) {
        return app.api.PageManager.navigateToID(this.properties.link)
      } else if (this.hasContentLink) {
        return app.api.PageManager.openExplicitContentFromID(this.properties.content);
      }
    }
  },
  computed: {
    hasSceneLink() {
      return this.properties.link !== null
    },
    hasContentLink () {
      return this.properties.content !== null
    },
    getSrc() {
      if(this.properties.src)
        return app.api.Utils.getMediaPath(this.properties.src.src)
    },
    getPosition() {
      return {
          left: this.properties.positionData.xPosition + 'px',
          top: this.properties.positionData.yPosition + 'px'
      }
    },
    getSize() {
        return {
            width: this.properties.componentSize.width !== '' ? this.properties.componentSize.width : 'auto',
            height: this.properties.componentSize.height !== '' ? this.properties.componentSize.height : 'auto' 
        }
    },
    mediaType() {
      if(this.properties.src)
        return app.api.Utils.getMediaType(this.properties.src.src)
    }
  }
};
</script>

<style lang="scss" scoped>
.backgroundMedia {
  position: absolute;
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: center;

  &>*{ 
    pointer-events: none;
    max-width: 100%;
    max-height: 100%;
  }

  &--link {
    cursor: pointer;
  }
}
</style>