<template>
  <AppLoaderVue v-if="!ready"></AppLoaderVue>
  <AppContainer v-else></AppContainer>
</template>

<script>
import AppLoaderVue from './components/core/AppLoader.vue';
import AppContainer from './components/core/AppContainer.vue';

export default {
  components: {
    AppLoaderVue,
    AppContainer
  },
  created() {
    window.app.vm = this;
  },
  computed: {
    ready() {
      return this.$store.getters.ready;
    }
  },
};
</script>

<style lang="scss">
html,
body {
  height: 100vh;
  max-width: 100vw;
  scroll-behavior: smooth;
  background-color: var(--dark-grey);
  font-size: 18px;
  overflow: hidden;
  color:#fff;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

img, button, a {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}


// Global Styling 


// Scrollbar
* {
  scrollbar-color: var(--dark-grey) var(--light-grey);
  scrollbar-width: thin;

  & *::-webkit-scrollbar {
    width: 8px;
  }

  & *::-webkit-scrollbar-track {
    background: transparent;
    background: rgba(255,255,255,0.1);
    background: linear-gradient(180deg, rgba(255,255,255,0.2) 0%, rgba(255, 255, 255, 0) 100%);
  }

  & *::-webkit-scrollbar-thumb {
    background: rgba(255,255,255,0.3);
    height: 50%;
  }
}

p {
  display: block;
  margin-bottom: 1em;

  &:last-of-type{
    margin-bottom: 0em;
  }

  >a {
    display: inline;
    color: var(--secondary-color)
  }
}

strong, b {
  font-weight: 900;
}

em {
  font-style: italic;
}


button {
  cursor: pointer;

  &--disabled {
    cursor: default;
    pointer-events: none;
  }
}
</style>