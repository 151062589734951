<template>
    <div class="MediaSlideShow" ref="mediaslideshow" :class="{ 'touched': navigationOn }" 
        @pointerdown="handlePointerDown" @pointermove="handlePointerMove">

        <!-- media -->
        <div v-for="media, i in mediaList" class="slide"
            :class="{ 'active': i == currentMediaIndex, 'comming': isComing(i) }">
            <!-- if video -->
            <VideoPlayer :ref="`videoplayer${i}`" v-if="isVideo(media.src)" :src="getSrc(media.src)" :controls="false"
                :autoplay="autoplayVideos" :loop="loopVideos" :coverFixedSize="true" class="videoplayer"
                @stopped="showNavigation()"></VideoPlayer>
            <!-- if image -->
            <img @click="showNavigation()" v-else-if="media" :src="getSrc(media.src)">
            {{ media.id }}
        </div>

        <!-- forth and back arrows -->
        <div class="MediaSlideShow__navigation" v-if="mediaList.length > 1" @click="showNavigation()">
            <a class="prev" @click="prev()"><img src="@/assets/img/ui/arrow-back.svg"></a>
            <a class="next" @click="next()"><img src="@/assets/img/ui/arrow-forth.svg"></a>
        </div>

        <!-- progress dots -->
        <div class="MediaSlideShow__progress" v-if="mediaList.length > 1">
            <a v-for="(media, i) in mediaList" class="dot" :class="{ 'active': i == currentMediaIndex }"
                @click="specific(i)"></a>
        </div>
    </div>
</template>


<script>
import VideoPlayer from './VideoPlayer.vue'

export default {
    components: { VideoPlayer },
    props: {
        mediaList: Array,
        autoplayVideos: false,
        loopVideos: false,
    },
    data() {
        return {
            currentMediaIndex: 0,
            navigationOn: false,
            navTimer: null,
            currentIndex: 0,
            startX: 0,
            swipeThreshold: 50,
        }
    },
    mounted() {

        // write isVideo to data object
        this.mediaList.forEach(media => {
            media.isVideo = this.isVideo(media.src);
        });

    },
    methods: {

        getSrc(src) {
            return app.api.Utils.getMediaPath(src);
        },

        isVideo(src) {
            return app.api.Utils.getMediaType(src) == 'video';
        },

        prev() {
            this.resetVideo();
            this.currentMediaIndex = (this.mediaList.length + (this.currentMediaIndex - 1)) % this.mediaList.length;
        },

        next() {
            this.resetVideo();
            this.currentMediaIndex = (this.currentMediaIndex + 1) % this.mediaList.length;
        },

        specific(index) {
            this.resetVideo();
            this.currentMediaIndex = index;
        },

        isComing(index) {
            // placing media on right before animating in
            if (this.currentMediaIndex < index) {
                return true;
            }
            return false;
        },

        showNavigation() {
            clearTimeout(this.navTimer);
            this.navigationOn = true;
            this.navTimer = setTimeout(() => {
                this.hideNavigation();
            }, 2000);
        },

        hideNavigation() {
            this.navigationOn = false;
        },

        resetVideo() {
            // reset only the current video
            if (this.mediaList[this.currentMediaIndex].isVideo) {
                this.$refs['videoplayer' + this.currentMediaIndex][0].reset();
            }
        },

        handlePointerDown(event) {
            // Check if the event is a touch or a mouse event
            if (event.pointerType === 'touch' || event.pointerType === 'pen') {
                this.startX = event.clientX;
            }
            
        },

        handlePointerMove(event) {
            if (!this.startX) return;

            const currentX = event.clientX;
            const deltaX = currentX - this.startX;

            console.log(deltaX)

            if (deltaX > 0) {
                // Swiped left-to-right (previous)
                if (this.currentMediaIndex > 0)
                this.prev();
            } else if (deltaX < 0) {
                // Swiped right-to-left (next)
                if (this.currentMediaIndex < this.mediaList.length-1)
                this.next();
            }

            this.startX = 0; // Reset startX for the next pointerdown event
        },

    }
};
</script>

<style lang="scss">
.MediaSlideShow {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background-color: black;

    .slide {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        transform: translateX(-50%);
        transition: all .4s var(--tk-easeinout);
        pointer-events: none;

        &.active {
            opacity: 1;
            transform: translateX(0);
            pointer-events: all;
        }

        &.comming {
            // place media in the right before animating
            transform: translateX(50%);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }



    }


    // show navigation arrows on hover (non-touch) 
    &:hover {
        @media (hover: hover) and (pointer: fine) {
            .MediaSlideShow__navigation {
                opacity: 1;
            }
        }
    }

    // show navigation arrows on touch
    &.touched {
        @media (pointer: coarse) {
            .MediaSlideShow__navigation {
                opacity: 1;
            }
        }
    }



    &__navigation {

        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0px;
        pointer-events: none;
        opacity: 0;
        transition: opacity 1s;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100px;
            pointer-events: all;
            cursor: pointer;
            position: relative;
            transition: all 0.5s;



            img {
                width: 20px;
                width: 20px;
                transition: transform 0.3s;
            }


            &:active {
                transition: none;
                background-color: rgba(255, 255, 255, 0.1);

                img {
                    transform: scale(0.9);
                    transition: none;
                }
            }


        }
    }

    &__progress {

        position: absolute;
        width: 100%;
        bottom: 20px;
        display: flex;
        justify-content: center;
        gap: 15px;

        a {
            display: block;
            width: 20px;
            height: 20px;
            border: 2px solid #fff;
            border-radius: 50%;
            transition: background-color 0.7s;
            cursor: pointer;

            &.active {
                background-color: #fff;
            }
        }
    }

}
</style>