<template>
   <div class="PageBackground" ref="pageBackground" :style="{ width: getMediaWidth + 'px' }" :class="{'PageBackground--scrollable': scrollingBackground}"
        :data-background-width="getMediaWidth">
        <video :src="backgroundVideo" ref="bgVideo" v-if="hasVideo" @loadedmetadata="measureVideoWidth" muted autoplay loop
            playsinline webkit-playsinline type="video/mp4" @contextmenu.prevent="" disablepictureinpicture></video>
        <img v-if="hasImage" :src="backgroundImage" :alt="background.image.name">
    </div>
</template>

<script>
export default {
    props: ["background", "hasContentComponentOpen", "scrollingBackground"],

    data() {
        return {
            videoWidth: 0
        }
    },

    methods: {
        measureVideoWidth() {
            const videoElement = this.$refs.bgVideo;
            if (videoElement) {
                this.videoWidth = videoElement.videoWidth;
            }
        },
    },

    computed: {
        hasVideo() {
            return Object.keys(this.background)[0] === 'video'
        },
        getMediaWidth() {
            if (this.hasImage) {
                return this.background.image.width;
            } else if (this.hasVideo && this.videoWidth > 0) {
                return this.videoWidth;
            }
        },
        backgroundVideo() {
            if (this.hasVideo) {
                return app.api.Utils.getMediaPath(this.background.video.src)
            } else {
                return null;
            }
        },
        hasImage() {
            return Object.keys(this.background)[0] === 'image'
        },
        backgroundImage() {
            if (this.hasImage) {
                return app.api.Utils.getMediaPath(this.background.image.src);

            } else {
                return null;
            }
        }
    },

   


};
</script>

<style lang="scss" scoped>
.PageBackground {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: -1;

    &>* {
        position: absolute;
        // width: 100%;
        height: 100%;
    }

    &--scrollable {
        cursor: grab;
    }
}
</style>