const config = {
    name: 'Siemens ECDP',
    language: 'en',

    mediaFolder: '/media/',
    manifest: 'assets/json/@lang/manifest.json'
}


if (window.location.hostname === "localhost") {
    config.manifest = 'https://siemens-ecdp-umbraco.azurewebsites.net/output/ecdp/assets/json/@lang/manifest.json';
    config.mediaFolder = 'https://siemens-ecdp-umbraco.azurewebsites.net/media/';
} 


export default config